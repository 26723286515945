import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, CircularProgress, Typography, Box } from '@mui/material';

function CertificateVerification() {
    const [certificateID, setCertificateID] = useState('');
    const [certificateDetails, setCertificateDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchCertificateDetails = async () => {
        setLoading(true);
        setError(false); // Reset error state on each click
        const url = 'https://sheetdb.io/api/v1/j55c6esk1kge8'; // Your SheetDB API URL

        try {
            const response = await axios.get(url);
            const rows = response.data;

            const found = rows.find(row => row['Certificate ID'] === certificateID);

            if (found) {
                setCertificateDetails({
                    companyName: found['Company Name'],
                    placeOfBusiness: found['Place of Business'],
                    dateOfIssue: found['Date of Issue'],
                    dateOfExpiry: found['Date of Expiry'],
                    nameOfCertification: found['Name of Certification'],
                });
                setError(false);
            } else {
                setCertificateDetails(null);
                setError(true); // Set error if no certificate is found
            }
        } catch (error) {
            console.error('Error fetching data from SheetDB:', error);
            setError(true); // Set error if there's an issue with fetching data
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container 
            maxWidth="sm" 
            style={{ 
                backgroundColor: '#fff', 
                padding: '20px', 
                borderRadius: '10px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                marginTop: '40px'  // This moves the container slightly down from the top
            }}
        >
            <Box textAlign="center" marginBottom={3}>
                <Typography variant="h5" color="primary">Certificate Verification</Typography>
            </Box>
            <TextField
                fullWidth
                variant="outlined"
                label="Enter Certificate ID"
                value={certificateID}
                onChange={(e) => setCertificateID(e.target.value)}
                style={{ marginBottom: '20px' }}
            />
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={fetchCertificateDetails}
                style={{ marginBottom: '20px' }}
                disabled={loading}
            >
                {loading ? <CircularProgress size={24} /> : 'Verify'}
            </Button>

            {loading && (
                <Box textAlign="center" className="loading">
                    <Typography variant="body1" color="primary">Loading...</Typography>
                </Box>
            )}

            {certificateDetails && !loading && (
                <Box className="certificate-details" marginTop={3}>
                    <Typography variant="h6" color="primary">Certificate Verified</Typography>
                    <Typography variant="body1"><strong>Company Name:</strong> {certificateDetails.companyName}</Typography>
                    <Typography variant="body1"><strong>Place of Business:</strong> {certificateDetails.placeOfBusiness}</Typography>
                    <Typography variant="body1"><strong>Date of Issue:</strong> {certificateDetails.dateOfIssue}</Typography>
                    <Typography variant="body1"><strong>Date of Expiry:</strong> {certificateDetails.dateOfExpiry}</Typography>
                    <Typography variant="body1"><strong>Name of Certification:</strong> {certificateDetails.nameOfCertification}</Typography>
                </Box>
            )}

            {error && !loading && (
                <Box textAlign="center" marginTop={2}>
                    <Typography variant="body1" color="error">No certificate found with this ID.</Typography>
                </Box>
            )}
        </Container>
    );
}

export default CertificateVerification;
